import "./styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { REACT_APP_API_PATH as baseURL } from "../../config";
import { customFetch } from "../../services/requestServices";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useURLInfo } from "../../utils/useURLInfo";
import {
  handleParentClick,
  clearAllSelectedFilters,
  updateUserSelectedFilter,
  handleChildFilterUnchecked,
  displaySelectedFilters,
  handleParentFilterUnchecked,
  generateParamString,
  capitalizeFirstLetter,
  findChildFilterSubElements,
  getOverallTicketType,
  FilterSubOptions,
  getUpdatedItemKey,
  updateUrlWithFilters,
} from "../../utils/clearAllSelectedFilters";
import {
  handleInputChange,
  getSubFilterClassName,
  handleHideAllSuboptions,
  handleSelectedColumns,
  removeSpaceswithUnderScores,
  findCheckedStatusFromUrlParams,
  showFiltersDropdown,
} from "../../utils/TicketsTableFunctions";
import Header from "../../components/Header";
import { defaultFiltersForTicketSummary } from "./data";
import { TicketsTable } from "../../components/TicketsTable";

const TicketSummaryScreenComponent = () => {
  const [allFilters, setAllFilters] = useState({});

  const [filterOptions, setFilterOptions] = useState([]);

  const [parameterString, setParameterString] = useState("");

  const [beforeParamString, setBeforeParamString] = useState("");

  const [selectedColumn, setSelectedColumn] = useState({
    columnName: "",
    forward: false,
  });

  const [userSelectedFilter, setUserSelectedFilter] = useState(
    defaultFiltersForTicketSummary
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState(
    searchParams.get("search") || ""
  );

  const [debouncedInputValue, setDebouncedInputValue] = useState("");

  const [displayDownloadProgress, setDisplayDownloadProgress] =
    useState("none");

  const [rowData, setRowData] = useState([]);

  const [url, setUrl] = useState(`${baseURL}v2/ticket/?limit=15`);
  const [isLoading, setIsLoading] = useState(false);
  const [countTickets, setCountTickets] = useState(null);

  const prevFetchUrl = useRef(null);

  const urlUserSelectedParams = useURLInfo();

  const fetFiltersDataCount = useRef(0);

  const updateHandleInputChange = (updatedInput) => {
    setInputValue(updatedInput);
  };

  const getSearchParamFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("search") || "";
  };

  useEffect(() => {
    const initialSearch = getSearchParamFromURL();
    if (initialSearch) {
      setInputValue(initialSearch);
      setDebouncedInputValue(initialSearch);
    }
  }, []);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 1000);
    return () => clearTimeout(delayInputTimeoutId);
  }, [inputValue]);
  const handleSetSelectedColumn = (newSelectedColumnObject) => {
    setSelectedColumn(newSelectedColumnObject);
  };

  const handleSetBeforeParamString = (beforeParamString) => {
    setBeforeParamString(beforeParamString);
  };

  useEffect(() => {
    const sortDirection = selectedColumn.forward ? "" : "-";

    const columnNameWithSign = `${sortDirection}${removeSpaceswithUnderScores(
      selectedColumn.columnName.toLowerCase()
    )}`;

    const searchParams = new URLSearchParams(parameterString);

    if (selectedColumn.columnName) {
      setUrl("");

      setRowData([]);
      window.scrollTo(0, 0);

      searchParams.set("ordering", columnNameWithSign);
    }

    if (debouncedInputValue) {
      searchParams.set("search", debouncedInputValue);
    } else {
      searchParams.delete("search");
    }

    const updatedParamString = `&${searchParams.toString()}`;

    setParameterString(updatedParamString);
  }, [selectedColumn, debouncedInputValue, parameterString]);

  useEffect(() => {
    if (inputValue) {
      setSearchParams({ search: inputValue });
    } else {
      setSearchParams({});
    }
  }, [inputValue, setSearchParams]);

  const findSuboptions = (subObjArr, parentKey, subOptionsFromParam) => {
    return subObjArr.map((sub) => ({
      type: sub.name,
      colour: sub.color_code,
      id: sub.id,
      checked: subOptionsFromParam
        ? findCheckedStatusFromUrlParams(subOptionsFromParam, sub.id, parentKey)
        : false,
      parent: parentKey,
    }));
  };
  const findFilterOptions = (filterObj, urlParams) => {
    const keyCollection = Object.keys(urlParams);

    const isOpenTicketsChecked =
      urlParams["open tickets"] !== undefined
        ? urlParams["open tickets"]
        : true;
    const isClosedTicketsChecked =
      urlParams["closed tickets"] !== undefined
        ? urlParams["closed tickets"]
        : false;

    const isClosedDateSelected = checkIfClosedDateIsChecked(
      urlParams["closedDate"]
    );

    const selectedFirstResponseSLA = urlParams["first_response_sla"];

    return Object.keys(filterObj)
      .map((item) => {
        return {
          type: item,
          checked: keyCollection.includes(getUpdatedItemKey(item)),
          isHighlighted: false,
          suboptions: findSuboptions(
            filterObj[item],
            item,
            urlParams[getUpdatedItemKey(item)]
          ),
          showsuboptions: false,
          disabled: false,
        };
      })
      .concat([
        {
          type: "first_response_sla",
          checked: selectedFirstResponseSLA != "",
          isHighlighted: false,
          suboptions: [
            {
              type: "Achieved",
              id: "Achieved",
              checked: selectedFirstResponseSLA == "Achieved",
              parent: "first_response_sla",
              showsuboptions: false,
            },
            {
              type: "Breached",
              id: "Breached",
              checked: selectedFirstResponseSLA == "Breached",
              parent: "first_response_sla",
              showsuboptions: false,
            },
          ],
          showsuboptions: false,
        },
        {
          type: "date",
          checked: urlParams["date"]
            ? checkIfDateIsChecked(urlParams["date"])
            : false,
          isHighlighted: false,
          suboptions: [
            {
              type: "Date created",
              id: "Date created",
              checked: false,
              parent: "date",
              suboptions: [
                {
                  type: "From",
                  id: "From",
                  checked: false,
                  parent: "Date created",
                },
                {
                  type: "To",
                  id: "To",
                  checked: false,
                  parent: "Date due",
                },
              ],
              showsuboptions: false,
              disabled: false,
            },
            {
              type: "Date due",
              id: "Date due",
              checked: false,
              parent: "date",
              suboptions: [
                {
                  type: "From",
                  id: "From",
                  checked: false,
                  parent: "Date created",
                  showsuboptions: false,
                },
                {
                  type: "To",
                  id: "To",
                  checked: false,
                  parent: "Date due",
                  showsuboptions: false,
                },
              ],
              showsuboptions: false,
              disabled: false,
            },
          ],
          showsuboptions: false,
        },
        {
          type: "closedDate",
          checked: isClosedDateSelected,
          isHighlighted: false,
          suboptions: [
            {
              type: "From",
              id: "From",
              checked: false,
              parent: "closedDate",
              showsuboptions: false,
            },
            {
              type: "To",
              id: "To",
              checked: false,
              parent: "closedDate",
              showsuboptions: false,
            },
          ],
          showsuboptions: false,
          disabled: false,
        },
        {
          type: "closed tickets",
          checked: isClosedTicketsChecked,
          isHighlighted: false,
          suboptions: [],
          showsuboptions: false,
          disabled: false,
        },
        {
          type: "open tickets",
          checked: isOpenTicketsChecked,
          isHighlighted: false,
          suboptions: [],
          showsuboptions: false,
          disabled: false,
        },
      ]);
  };

  const checkIfClosedDateIsChecked = (closedDate) =>
    closedDate?.From?.length > 0 || closedDate?.To?.length > 0;

  const checkIfDateIsChecked = (date) => {
    const isDateCreatedChecked =
      date["Date created"]?.From.length > 0 ||
      date["Date created"]?.To.length > 0;

    const isDateDueChecked =
      date["Date due"].From.length > 0 || date["Date due"]?.To.length > 0;

    return isDateCreatedChecked || isDateDueChecked;
  };

  const fetchDataForTicketsTable = async (customUrl) => {
    const fetchUrl = customUrl || url;
    if (fetchUrl === prevFetchUrl.current || !fetchUrl) return;
    prevFetchUrl.current = fetchUrl;

    setIsLoading(true);
    const data = await customFetch(fetchUrl, "GET");
    const results = data.results;
    const ticketsCount = data.count;
    if (customUrl) setRowData(results);
    else setRowData([...rowData, ...results]);
    setCountTickets(ticketsCount);
    setUrl(data.next);
    setIsLoading(false);
  };

  const fetchFiltersData = async () => {
    const data = await customFetch(`${baseURL}v2/ticket/filter_config`, "GET");

    setAllFilters(data);
    setFilterOptions(findFilterOptions(data, urlUserSelectedParams));

    setUserSelectedFilter({
      ...userSelectedFilter,
      ...urlUserSelectedParams,
    });
  };

  const handleChildClick = (
    e,
    parentFilterType,
    childFilterId,
    parentFilterIndex,
    childFilterIndex,
    sub,
    childFilterType
  ) => {
    e.stopPropagation();

    updateUserSelectedFilter(
      e,
      parentFilterType,
      childFilterId,
      parentFilterIndex,
      childFilterIndex,
      sub,
      childFilterType,
      handleChildFilterUnchecked,
      userSelectedFilter,
      filterOptions,
      setFilterOptions,
      setUserSelectedFilter
    );
  };

  const isFilterNonEmpty = (filter) => filter.length > 0;

  const isDateFilterNonEmpty = (dateFilter) => {
    const { "Date created": dateCreated, "Date due": dateDue } = dateFilter;
    return [
      dateCreated["From"],
      dateCreated["To"],
      dateDue["From"],
      dateDue["To"],
    ].some(isFilterNonEmpty);
  };

  const isClosedDateFilterNonEmpty = (closedDateFilter) => {
    const { From, To } = closedDateFilter;
    return [From, To].some(isFilterNonEmpty);
  };

  const hasVisibleFilter = (key, value) => {
    switch (key) {
      case "date":
        return isDateFilterNonEmpty(value);
      case "closedDate":
        return isClosedDateFilterNonEmpty(value);
      case "closed tickets":
      case "open tickets":
        return Boolean(value);
      case "search":
        return Boolean(false);
      default:
        return isFilterNonEmpty(value);
    }
  };

  const checkClearAllVisibility = () => {
    const updatedUserSelectedFilter = { ...userSelectedFilter };

    const anyFilterVisible = Object.keys(updatedUserSelectedFilter).some(
      (key) => hasVisibleFilter(key, updatedUserSelectedFilter[key])
    );

    return anyFilterVisible ? "visible" : "hidden";
  };

  const removeCheckFromAllSuboptions = (suboptions, filterType) =>
    suboptions.map((subotpn) => ({
      ...subotpn,
      checked: false,
    }));

  const fetchExportsData = async () => {
    setDisplayDownloadProgress("inline-block");
    const response = await fetch(
      `${baseURL}v2/ticket/export/?${parameterString.slice(1)}`,
      {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Token ${localStorage.getItem("access_token")}`,
        },
      }
    );

    const blob = await response.blob();

    const exportsResponseUrl = window.URL.createObjectURL(new Blob([blob]));

    const link = document.createElement("a");

    link.href = exportsResponseUrl;
    const currentTime = new Date().toISOString().slice(0, 19).replace(/T/, "-"); // Format: YYYY-MM-DD-HH-MM-SS
    const filename = `Guardian-Export-${currentTime}.csv`;
    link.setAttribute("download", filename);

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);

    setDisplayDownloadProgress("none");
  };

  const handleExport = (e) => {
    fetchExportsData();
  };

  const filterFunction = (e) => {
    let input, filter, a;
    input = e.target;

    filter = input.value.toUpperCase();

    let div = document.getElementsByClassName(
      "filter_dropdown_child_options"
    )[0];

    a = div.getElementsByClassName("sub_filter_checkbox");

    for (const element of a) {
      let txtValue = element.textContent || element.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        element.style.display = "";
      } else {
        element.style.display = "none";
      }
    }
  };

  useEffect(() => {
    if (fetFiltersDataCount.current === 0) {
      fetchFiltersData();
      fetFiltersDataCount.current += 1;
    }
  }, []);

  useEffect(() => {
    setParameterString(
      generateParamString(
        userSelectedFilter,
        handleSetBeforeParamString,
        allFilters
      )
    );

    updateUrlWithFilters(userSelectedFilter);
  }, [userSelectedFilter]);

  useEffect(() => {}, [filterOptions]);

  useEffect(() => {}, [parameterString]);

  useEffect(() => {
    const elem = document.getElementsByClassName("filters_dropdown")[0];
    const handleHideAllFilters = (e) => {
      let fixedList = [
        "div-20",
        "img-10",
        "span-10",
        "parent_filter_option",
        "filter_dropdown_child_options",
        "searchInput",
        "firstLetter",
        "assignee_sub_filter",
        "sub_filter_checkbox",
        "single_selection_sub_filter",
        "assignee-value",
      ];
      if (!fixedList.includes(Array.from(e.target.classList)[0]))
        elem.classList.remove("show");
    };

    document.addEventListener("click", handleHideAllFilters);

    return () => {
      document.removeEventListener("click", handleHideAllFilters);
    };
  }, []);

  const handleOnDateHover = (e, subType) => {
    const dateSubTypeIndex = subType === "Date created" ? 0 : 1;
    const otherDateSubTypeIndex = dateSubTypeIndex === 0 ? 1 : 0;
    setFilterOptions(
      filterOptions.map((filterOption) => {
        if (filterOption.type === "date") {
          let tempSubOptions = [...filterOption.suboptions];
          tempSubOptions[dateSubTypeIndex].showsuboptions = true;
          tempSubOptions[otherDateSubTypeIndex].showsuboptions = false;

          return {
            ...filterOption,
            suboptions: tempSubOptions,
          };
        } else return filterOption;
      })
    );
  };

  const handleMouseOver = (e, index, filter) => {
    if (filter.type !== "closed tickets" && filter.type !== "open tickets") {
      handleParentClick(
        e,
        index,
        filter,
        filterOptions,
        setFilterOptions,
        userSelectedFilter,
        setUserSelectedFilter
      );
    } else {
      handleHideAllSuboptions(filterOptions, handleSetFilterOptions);
    }
  };

  const handleSetFilterOptions = (updatedFilterOptions) => {
    setFilterOptions(updatedFilterOptions);
  };

  return (
    <div className="ticketSummaryScreen">
      <div className="div">
        <div className="div-13">
          <Header
            parameters={parameterString}
            heading={getOverallTicketType(userSelectedFilter, allFilters)}
            handleInputChange={handleInputChange}
            updateHandleInputChange={updateHandleInputChange}
            inputValue={inputValue}
            buttonTitle="Add Ticket"
            showFiltersDropdown={() => showFiltersDropdown()}
            onMouseOver={handleMouseOver}
            handleParentFilterUnchecked={handleParentFilterUnchecked}
            filterOptions={filterOptions}
            getSubFilterClassName={getSubFilterClassName}
            onKeyUp={filterFunction}
            FilterSubOptions={FilterSubOptions}
            handleOnDateHover={handleOnDateHover}
            handleChildClick={handleChildClick}
            findChildFilterSubElements={findChildFilterSubElements}
            setFilterOptions={setFilterOptions}
            userSelectedFilter={userSelectedFilter}
            setUserSelectedFilter={setUserSelectedFilter}
            displaySelectedFilters={displaySelectedFilters(
              userSelectedFilter,
              capitalizeFirstLetter,
              allFilters,
              setUserSelectedFilter,
              filterOptions,
              setFilterOptions
            )}
            checkClearAllVisibility={`${checkClearAllVisibility()}`}
            clearAllSelectedFilters={() =>
              clearAllSelectedFilters(
                setUserSelectedFilter,
                setFilterOptions,
                filterOptions,
                removeCheckFromAllSuboptions
              )
            }
            style={{ display: `${displayDownloadProgress}` }}
            handleExport={handleExport}
            exportButtonTitle="Export"
          />
          <TicketsTable
            parameters={parameterString}
            handleSelectedColumns={handleSelectedColumns}
            orderedColumn={selectedColumn}
            clearAllSelectedFilters={() =>
              clearAllSelectedFilters(
                setUserSelectedFilter,
                setFilterOptions,
                filterOptions,
                removeCheckFromAllSuboptions
              )
            }
            urlUserSelectedParams={urlUserSelectedParams}
            handleSetSelectedColumn={handleSetSelectedColumn}
            rowData={rowData}
            isLoading={isLoading}
            countTickets={countTickets}
            setRowData={setRowData}
            url={url}
            fetchDataForTicketsTable={fetchDataForTicketsTable}
          />
        </div>
      </div>
    </div>
  );
};

const TicketSummaryScreen = () => {
  return <TicketSummaryScreenComponent />;
};

export default TicketSummaryScreen;
